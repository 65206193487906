import React from "react";
import CategoryMenuItem from "./category-menu-item/CategoryMenuItem";
import {StyledCategoryDropdown} from "./CategoryDropdownStyle";
import MegaMenu1 from "./mega-menu/MegaMenu1";
import {useSelector} from "react-redux";

export interface CategoryDropdownProps {
    open: boolean;
    position?: "absolute" | "relative";
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = ({open, position,}) => {
    // @ts-ignore
    const category = useSelector(state => state?.category)
    return (
        <StyledCategoryDropdown open={open} position={position}>
            {category.categories.slice(0, 9)?.map((item, index) => {
                return (
                    <CategoryMenuItem
                        title={item.name}
                        href={`/products/category/${item.slug}`}
                        icon={item.icon}
                        caret={true}
                        key={index}
                    >
                        <MegaMenu1 data={{categories: item.mega_category_child} || {}}/>
                    </CategoryMenuItem>
                );
            })}
            <CategoryMenuItem
                title="All Categories"
                href={'/categories'}
                icon={'icons/categories'}
                caret={false}
            />
        </StyledCategoryDropdown>
    );
};

CategoryDropdown.defaultProps = {
    position: "absolute",
};

export default CategoryDropdown;
