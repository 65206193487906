import IconButton from "@component/buttons/IconButton";
import Image from "@component/Image";
import Link from "next/link";
import React, {useEffect, useState} from "react";
import Box from "../Box";
import Categories from "../categories/Categories";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Icon from "../icon/Icon";
import MiniCart from "../mini-cart/MiniCart";
import SearchBox from "../search-box/SearchBox";
import Login from "../sessions/Login";
import Sidenav from "../sidenav/Sidenav";
import {Tiny} from "../Typography";
import StyledHeader from "./HeaderStyle";
import UserLoginDialog from "./UserLoginDialog";
import {useDispatch, useSelector} from "react-redux";
import {getUser, getWishlist} from "@store/userReducer";
import {getCart} from "@store/cartReducer";
import Avatar from "@component/avatar/Avatar";

type HeaderProps = {
    isFixed?: boolean;
    className?: string;
};

const Header: React.FC<HeaderProps> = ({isFixed, className}) => {
    const [open, setOpen] = useState(false);
    const toggleSidenav = () => setOpen(!open);

    // @ts-ignore
    const cartItems = useSelector(state => state.cart.products)
    // @ts-ignore
    const settings = useSelector(state => state.settings.data)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUser({}))
    }, [])

    // @ts-ignore
    const user = useSelector(state => state.user)
    useEffect(() => {
        // @ts-ignore
        dispatch(getCart(user.auth))
        dispatch({type: 'cart/auth', payload: user.auth})
        if(user.auth === true) {
            dispatch(getWishlist({}))
        }
    },[user.auth])

    const cartHandle = (
        <FlexBox ml="20px" alignItems="flex-start">
            <IconButton bg="gray.200" p="12px">
                <Icon size="20px">icons/bag</Icon>
            </IconButton>

            {!!cartItems.length && (
                <FlexBox
                    borderRadius="300px"
                    bg="error.main"
                    px="5px"
                    py="2px"
                    alignItems="center"
                    justifyContent="center"
                    ml="-1rem"
                    mt="-9px"
                >
                    <Tiny color="white" fontWeight="600">
                        {cartItems.length}
                    </Tiny>
                </FlexBox>
            )}
        </FlexBox>
    );

    return (
        <StyledHeader className={className}>
            <Container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
            >
                <FlexBox className="logo" alignItems="center" mr="1rem">
                    <Link href="/">
                        <a>
                            <Image src={settings?.logo || '/assets/images/logo.png'} alt="logo"/>
                        </a>
                    </Link>

                    {isFixed && (
                        <div className="category-holder">
                            <Categories open={false}>
                                <FlexBox color="text.hint" alignItems="center" ml="1rem">
                                    <Icon>icons/categories</Icon>
                                    <Icon>icons/arrow-down-filled</Icon>
                                </FlexBox>
                            </Categories>
                        </div>
                    )}
                </FlexBox>

                <FlexBox justifyContent="center" flex="1 1 0">
                    <SearchBox/>
                </FlexBox>

                <FlexBox className="header-right" alignItems="center">
                    {user.auth === true ? (
                        <Link href="/profile">
                            <IconButton ml="1rem" bg="gray.200" p="8px">
                                {user.image ? (
                                    <Avatar src={user.image} size={28} />
                                ) : (
                                    <Avatar src="/assets/images/avatars/001-man.svg" size={28} />
                                )}
                            </IconButton>
                        </Link>
                    ) : (
                        <UserLoginDialog
                            handle={
                                <IconButton ml="1rem" bg="gray.200" p="8px">
                                    <Icon size="28px">icons/user</Icon>
                                </IconButton>
                            }
                        >
                            <Box>
                                <Login/>
                            </Box>
                        </UserLoginDialog>
                    )}
                    <Sidenav
                        handle={cartHandle}
                        position="right"
                        open={open}
                        width={380}
                        toggleSidenav={toggleSidenav}
                    >
                        <MiniCart toggleSidenav={toggleSidenav}/>
                    </Sidenav>
                </FlexBox>
            </Container>
        </StyledHeader>
    );
};

export default Header;
