import FlexBox from "@component/FlexBox";
import Link from "next/link";
import React, {Fragment} from "react";
import Button from "../buttons/Button";
import Divider from "../Divider";
import Icon from "../icon/Icon";
import Typography, {H5, Paragraph, Tiny} from "../Typography";
import {StyledMiniCart} from "./MiniCartStyle";
import {useDispatch, useSelector} from "react-redux";

type MiniCartProps = {
    toggleSidenav?: () => void;
};

const MiniCart: React.FC<MiniCartProps> = ({toggleSidenav}) => {
    // @ts-ignore
    const cartItems = useSelector(state => state.cart.products)

    const dispatch = useDispatch()
    const handleCartAmountChange = (amount, product) => () => {
        dispatch({
            type: 'cart/update',
            payload: {
                ...product,
                quantity: amount,
            }
        })
    }
    const getTotalPrice = () => {
        return (
            cartItems.reduce(
                (accumulator, item) => accumulator + item.price * item.quantity,
                0
            ) || 0
        );
    };

    return (
        <StyledMiniCart>
            <div className="cart-list">
                <FlexBox alignItems="center" m="0px 20px" height="74px">
                    <Icon size="1.75rem">icons/bag</Icon>
                    <Typography fontWeight={600} fontSize="16px" ml="0.5rem">
                        {cartItems.length} item
                    </Typography>
                </FlexBox>

                <Divider/>

                {!!!cartItems.length && (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100% - 80px)"
                    >
                        <img
                            src="/assets/images/logos/shopping-bag.svg"
                            width="90px"
                            height="100%"
                            alt=""
                        />
                        <Paragraph
                            mt="1rem"
                            color="text.muted"
                            textAlign="center"
                            maxWidth="200px"
                        >
                            Your shopping bag is empty. Start shopping
                        </Paragraph>
                    </FlexBox>
                )}
                {cartItems.map((item, index) => (
                    <Fragment key={index}>
                        <div className="cart-item">
                            <FlexBox alignItems="center" flexDirection="column">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    padding="5px"
                                    size="none"
                                    borderColor="primary.light"
                                    borderRadius="300px"
                                    onClick={handleCartAmountChange(item.quantity + 1, item)}
                                >
                                    <Icon variant="small">icons/plus</Icon>
                                </Button>
                                <Typography fontWeight={600} fontSize="15px" my="3px">
                                    {item.quantity}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    padding="5px"
                                    size="none"
                                    borderColor="primary.light"
                                    borderRadius="300px"
                                    onClick={handleCartAmountChange(item.quantity - 1, item)}
                                    disabled={item.quantity === 1}
                                >
                                    <Icon variant="small">icons/minus</Icon>
                                </Button>
                            </FlexBox>

                            <Link href={`/product/${item.slug}`}>
                                <a className="h-10 w-10 mx-4">
                                    <img
                                        src={item.image || "/assets/images/products/iphone-x.png"}
                                        alt={item.name}
                                    />
                                </a>
                            </Link>

                            <div className="product-details">
                                <Link href={`/product/${item.slug}`}>
                                    <a>
                                        <H5 className="title" fontSize="14px">
                                            {item.name}
                                        </H5>
                                    </a>
                                </Link>
                                <Tiny color="text.muted">
                                    ${item.price.toFixed(2)} x {item.quantity}
                                </Tiny>
                                <Typography
                                    fontWeight={600}
                                    fontSize="14px"
                                    color="primary.main"
                                    mt="4px"
                                >
                                    ${(item.quantity * item.price).toFixed(2)}
                                </Typography>
                            </div>

                            <Icon
                                className="clear-icon"
                                size="1rem"
                                ml="1.25rem"
                                onClick={handleCartAmountChange(0, item)}
                            >
                                icons/close
                            </Icon>
                        </div>
                        <Divider/>
                    </Fragment>
                ))}
            </div>

            {!!cartItems.length && (
                <Fragment>
                    <Link href="/checkout">
                        <Button
                            variant="contained"
                            color="primary"
                            m="1rem 1rem 0.75rem"
                            onClick={toggleSidenav}
                        >
                            <Typography fontWeight={600}>
                                Checkout Now (${getTotalPrice().toFixed(2)})
                            </Typography>
                        </Button>
                    </Link>
                    <Link href="/cart">
                        <Button
                            color="primary"
                            variant="outlined"
                            m="0px 1rem 0.75rem"
                            onClick={toggleSidenav}
                        >
                            <Typography fontWeight={600}>View Cart</Typography>
                        </Button>
                    </Link>
                </Fragment>
            )}
        </StyledMiniCart>
    );
};

MiniCart.defaultProps = {
    toggleSidenav: () => {
    },
};

export default MiniCart;
