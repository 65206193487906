import React, {Fragment, useEffect, useState} from "react";
import Box from "../Box";
import FlexBox from "../FlexBox";
import Icon from "../icon/Icon";
import TextField from "../text-field/TextField";
import StyledSearchBox from "./SearchBoxStyle";
import {useRouter} from "next/router";
import Sidenav from "@component/sidenav/Sidenav";
import Typography from "@component/Typography";
import {DashboardNavigationWrapper, StyledDashboardNav} from "@component/layout/DashboardStyle";
import navbarNavigations from "@data/navbarNavigations";

export interface SearchBoxProps {
}

const SearchBox: React.FC<SearchBoxProps> = () => {
    const [key, setKey] = useState('')
    const router = useRouter()
    const handleSearch = () => {
        if(key?.length > 0) {
            router.push('/search/' + key)
        }
    };

    useEffect(() => {
        if(router.pathname.indexOf('/search') === 0 && router.query?.key) {
            // @ts-ignore
            setKey(router.query?.key)
        }
    }, [router.query])

    return (
        <Box position="relative" flex="1 1 0" maxWidth="670px" mx="auto">
            <StyledSearchBox>
                <Icon className="search-icon" size="18px">
                    icons/search
                </Icon>
                <TextField
                    className="search-field"
                    placeholder="Search and hit enter..."
                    value={key}
                    fullwidth
                    onChange={e => setKey(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleSearch()
                        }
                    }}
                />
                <div className="category-dropdown" onClick={handleSearch}>
                    <FlexBox className="dropdown-handler" alignItems="center">
                        <span>Search</span>
                    </FlexBox>
                </div>

                <Box className="menu-button" ml="14px" cursor="pointer">
                    <Sidenav position="left" handle={ <Icon color="primary">icons/menu</Icon>}>
                        <DashboardNavigationWrapper px="0px" pb="1.5rem" color="gray.900">
                            {[{title: 'Menu'}].map((item) => (
                                <Fragment key={item.title}>
                                    <Typography p="26px 30px 1rem" color="text.muted" fontSize="12px">
                                        {item.title}
                                    </Typography>
                                    {navbarNavigations.map((item) => (
                                        <StyledDashboardNav
                                            isCurrentPath={ typeof window !== 'undefined' && window?.location?.pathname === item.url}
                                            href={item.url}
                                            key={item.title}
                                            px="1.5rem"
                                            mb="1.25rem"
                                        >
                                            <FlexBox alignItems="center">
                                                <span>{item.title}</span>
                                            </FlexBox>
                                        </StyledDashboardNav>
                                    ))}
                                </Fragment>
                            ))}
                        </DashboardNavigationWrapper>
                    </Sidenav>
                </Box>
            </StyledSearchBox>
        </Box>
    );
};

export default SearchBox;
