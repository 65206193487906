import Box from "@component/Box";
import Card from "@component/Card";
import FlexBox from "@component/FlexBox";
import Grid from "@component/grid/Grid";
import LazyImage from "@component/LazyImage";
import NavLink from "@component/nav-link/NavLink";
import Link from "next/link";
import React from "react";
import {StyledMegaMenu1} from "./MegaMenuStyle";

const MegaMenu1: React.FC<any> = ({
  data: { categories, rightImage, bottomImage },
  minWidth,
}) => {
  return categories ? (
    <StyledMegaMenu1 className="mega-menu">
      <Card ml="1rem" minWidth={minWidth} minHeight={400} boxShadow="regular">
        <FlexBox px="1.25rem" py="0.875rem">
          <Box flex="1 1 0">
            <Grid container spacing={4}>
              {categories?.map((item, ind) => (
                <Grid item md={3} key={ind}>
                  <NavLink className="title-link" href={`/products/category/${item.slug}`}>
                    {item.name}
                  </NavLink>
                  {item.mega_category_child?.map((sub, index) => (
                    <NavLink className="child-link" key={index} href={`/products/category/${sub.slug}`}>
                      {sub.name}
                    </NavLink>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Box>

          {rightImage && (
            <Link href={rightImage.href}>
              <a>
                <Box position="relative" width="153px" height="100%">
                  <LazyImage
                    src={rightImage.imgUrl}
                    layout="fill"
                    objectFit="contain"
                  />
                </Box>
              </a>
            </Link>
          )}
        </FlexBox>

        {bottomImage && (
          <Link href={bottomImage.href}>
            <a>
              <Box position="relative" height="170px">
                <LazyImage
                  src={bottomImage.imgUrl}
                  layout="fill"
                  objectFit="cover"
                />
              </Box>
            </a>
          </Link>
        )}
      </Card>
    </StyledMegaMenu1>
  ) : null;
};

MegaMenu1.defaultProps = {
  minWidth: "760px",
};

export default MegaMenu1;
