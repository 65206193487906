import {Chip} from "@component/Chip";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Icon from "../icon/Icon";
import NavLink from "../nav-link/NavLink";
import StyledMobileNavigationBar from "./MobileNavigationBar.style";
import {useSelector} from "react-redux";

const MobileNavigationBar: React.FC = () => {
  const width = useWindowSize();
  // @ts-ignore
  const cartItems = useSelector(state => state.cart.products)
  // @ts-ignore
  const user = useSelector(state => state.user)

  const list = [
    {
      title: "Home",
      icon: "icons/home",
      href: "/",
    },
    {
      title: "Category",
      icon: "icons/category",
      href: "/mobile-category-nav",
    },
    {
      title: "Cart",
      icon: "icons/bag",
      href: "/cart",
    },
    {
      title: user.auth ? "Account" : "Login",
      icon: "icons/user-2",
      href:  user.auth ? "/profile" : '/login',
    },
  ];

  return (
    width <= 900 && (
      <StyledMobileNavigationBar>
        {list.map((item) => (
          <NavLink className="link" href={item.href} key={item.title}>
            <Icon className="icon" variant="small">
              {item.icon}
            </Icon>
            {item.title}

            {item.title === "Cart" && !!cartItems.length && (
              <Chip
                bg="primary.main"
                position="absolute"
                color="primary.text"
                fontWeight="600"
                px="0.25rem"
                top="4px"
                left="calc(50% + 8px)"
              >
                {cartItems.length}
              </Chip>
            )}
          </NavLink>
        ))}
      </StyledMobileNavigationBar>
    )
  );
};


export default MobileNavigationBar;
