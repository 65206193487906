const navbarNavigations = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Brands",
    url: "/brands",
  },
  {
    title: "Solutions",
    url: "https://solutions.genx.ae/",
    extLink: true,
  },
  {
    title: "About Us",
    url: "/about",
  },
  {
    title: "Sell on GenX",
    url: "/sell_on_genx",
  },
];

export default navbarNavigations;
